.signin-page {
  width: 1140px;
  max-width: calc(100% - 8px - 8px);
  margin: 0 auto;
  padding: 8px;
}

.signin-page .form {
  width: 400px;
  max-width: 100%;
  margin: 32px auto;
  border: unset;
  background-color: #FFF;
  color: #333;
  box-shadow: 0px 2px 10px 0px #ededed;
  padding: 20px;
}

.signin-page .form .header {
  padding: 16px;
  border-bottom: 1px solid #efefef;
  font-size: 18px;
  font-weight: 500;
}

.signin-page .form .error {
  border-color: #af4141 !important;
  -webkit-box-shadow: 0px 0px 9px 0px #af4141;
          box-shadow: 0px 0px 9px 0px #af4141;
}

.signin-page .form .login-response {
  padding: 10px;
  margin-top: 20px;
}

.signin-page .form .forgot {
  margin: 0;
  cursor: pointer;
  color: #686868;
}

.signin-page .form .forgot:hover {
  color: #111;
}

.signin-page .form .fields {
  padding: 16px;
}

.signin-page .form .fields .input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 16px;
}

.signin-page .form .fields .input span {
  font-size: 14px;
  font-weight: 500;
}

.signin-page .form .fields .input input {
  outline: none;
  width: calc(100% - 12px - 12px);
  margin-top: 4px;
  padding: 12px;
  border: 1px solid #e9e9e9;
  border-radius: 2px;
  background-color: #f1f1f1;
  font-size: 18px;
  color: #333;
  border: unset;
}

.signin-page .form .controls {
  padding: 16px;
}

.signin-page .form .controls .btn-signin {
  outline: none;
  border: none;
  width: 100%;
  padding: 12px 0;
  border-radius: 2px;
  background-color: #e23b45;
  color: #EEE;
  font-size: 18px;
  cursor: pointer;
}

.signin-page .form .controls .seperator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 16px;
  font-size: 18px;
}

.signin-page .form .controls .seperator::before {
  content: ' ';
  width: 50%;
  height: 1px;
  background-color: #999;
}

.signin-page .form .controls .seperator span {
  padding: 0 16px;
}

.signin-page .form .controls .seperator::after {
  content: ' ';
  width: 50%;
  height: 1px;
  background-color: #999;
}

.signin-page .form .controls .login-with {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-top: 16px;
}

.signin-page .form .controls .login-with button {
  width: 47.1%;
}

.signin-page .form .controls .login-with button:nth-of-type(1) {
  border-color: #4267B2;
  color: #4267B2;
}

.signin-page .form .controls .login-with button:nth-of-type(1):hover {
  background-color: #4267B2;
  color: #FFF;
}

.signin-page .form .controls .login-with button:nth-of-type(2) {
  border-color: #DB4437;
  color: #DB4437;
}

.signin-page .form .controls .login-with button:nth-of-type(2):hover {
  background-color: #DB4437;
  color: #FFF;
}
.login-after-change{
  color: #fff;
  background: #000;
  text-decoration: none;
  padding: 10px;
}
.error-msg{
  display: block;
  margin: 10px;
  padding: 5px;
  background: #ffc107;
}
/*# sourceMappingURL=SigninPage.css.map */