.searchproducts .search-bar label {
  visibility: hidden;
  }

.searchproducts .search-bar input[type='text'] {
    outline: none;
    width: 100%;
    /* max-width: calc(100% - 10px - 40px); */
    height: 35px;
    border: 1px solid #EEE;
    border-radius: 24px;
    padding: 5px 10px;
    padding-left: 40px;
    background-color: #EEE;
    font-size: 18px;
    color: #333;
    }

.searchproducts .suggestions {
    display: none;
    position: absolute;
    width: calc(100% - 1px - 1px);
    top: 100%;
    left: 0;
    padding-bottom: 32px;
    border: 1px solid #EEE;
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
    background-color: #EEE;
    overflow-y: hidden;
    z-index: 9;
}
.searchproducts .suggestions.visible {
    display: block;
}
.searchproducts .suggestions .list {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding-bottom: 32px;
    height: 285px;
    overflow: auto;
    overflow-x: hidden;
}
.searchproducts .search-bar {
    position: relative
}
.searchproducts .search-bar svg{
    position: absolute;
    top: 9px;
    left: 13px;
    font-size: 20px;
    color: #929292;
}
.options .basket.desktop-btn {
    width: 80px;
    height: 100%;
    background-color: #46bbc6;
    border: 0;
    border-radius: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    transition: all .2s ease-in-out;
    align-content: center;
    align-items: center;
}
.banner {
    margin-bottom: 15px;
    background-size: cover;
    background-position: center;
    height: 340px;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: #00000082;
    background-blend-mode: overlay;
}
.banners {
    display: flex;
    flex-wrap: wrap;
    column-gap: 15px;
}
.banner-medium-item {
     width: 59%;
}
.banner-small-item {
    width: 39%;
}
.banner-big-item {
    width: 78%;
}
.banner-xs-item {
    width: 20%;
}
.banner a {
    width: 100%;
    display: block;
}
.banner .itm-title {
    position: absolute;
    top: 0px;
    color: #fff;
    margin: 20px;
    word-break: break-word;
    box-sizing: border-box;
}
.banner .itm-title h2 {
    font-size: 30px;
}
.banner .itm-title h1 {
    font-size: 29px;
}
.banner span, .services-more a {
    color: #fff;
    font-size: 12px;
    line-height: normal;
    letter-spacing: 1px;
    background: #d6c277;
    padding: 3px 15px;
    font-weight: 600;
}

.featured-products .product-item .image-wrapper img,
.new-arrivals .product-item .image-wrapper img {
    height: 200px;
    padding: 10px;
}
.featured-products .title {
    font-size: 38px;
}
/* .featured-inner .product-item, 
.first-product .product-item {
    border: 2px solid #f86532;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
} */
.featured-inner .product-item .image-wrapper, 
.first-product .product-item .image-wrapper {
    padding: 30px;
}
.see-product-details {
    /* background: #f86532; */
    padding: 15px;
    text-align: center;
    /* border: 2px solid #f86532; */
    border-top: none !important;
    transition: all 250ms ease-in-out 0s;
    -webkit-transition: all 250ms ease-in-out 0s;
    width: 100%;
}
.see-product-details a {
    color: #fff !important;
    text-decoration: none;
}
.featured-inner .image-wrapper img {
    height: auto !important;
    margin-bottom: 15px;
}
.new-arrivals .flex-item {
    width: 25%;
    padding: 2px 1px 1px 2px;
}
.new-arrivals .first-product .product-item img{
    height: auto;
}
.banner-info {
    background: #f3f3f3;
    min-height: 330px;
}
.banner-info .services-item {
    background: #fff;
    padding: 25px;
    border-radius: 3px;
    margin-bottom: 20px;
}
.services-wrapper .services-item {
    width: 24%;
    margin-right: 10px;
}
.services-wrapper .services-item .main-icon {
    font-size: 40px;
    color: #ffffff !important;
    width: 50px;
    height: 50px;
    padding: 10px;
    background: #b3904d;
    border-radius: 50px;
}

.right-title {
    width: 40% !important;
    background: #9d7032ad;
    padding: 25px;
    border-radius: 5px;
    color: #fff;
}
@media screen and (max-width:425px) {
    .right-title {
        width: 100% !important;
    }
}
.img-category {
    text-align: center;
    height: 100px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.img-category img {
    height: 100%;
    border-radius: 50px;
    object-fit: cover;
    width: 100px;
}
.grid-item-title {
    color: #252525;
    font-weight: 500;
    text-align: center;
}
.App > .header {
    position: sticky;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 999;
    transition: all .2s ease-in-out;
    background: #fff;
}
.App > .header .navbar .navigation {
    font-weight: 500;
}
.App > .header .navbar .navigation {
    justify-content: flex-start;
}
.categories-choices .choice a {
    text-decoration: none;
    font-size: 14px;
    color: #212529;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 3px 0px;
}
.categories-choices .categ-name , .categories-choices .product-count{
    min-width: auto !important;
    text-align: start !important;
}
span.categ-name {
    margin-right: 5px;
}
.products-page .filters .attributes .attribute > .name {
    border-bottom: 1px solid #eaeaea;
}
.brand-choice {
    padding: 5px 10px;
    color: #808080;
    font-size: 12px;
    border: 1px solid #eee;
    border-radius: 50px;
    margin-right: 5px;
    margin-bottom: 5px;
}
.btn-change-view-type.ml-1 {
    display: flex;
    justify-content: flex-end;
}
.btn-change-view-type .menu span:first-child {
    margin: 5px;
}
.btn-change-view-type .menu span {
    color: #adadad;
}
.btn-change-view-type  .menu {
    font-size: 20px;
}
@media screen and (max-width:425px) {
    .App > .header {
        padding: 10px 8px;
    }
    .App > .header .topbar {
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .App > .header .logo  img{
        height: 40px;
    }
    .searchproducts {
        display: none;
    }
    .new-arrivals .flex-item {
        width: 50%;
    }
    .subscribe-form #mc-embedded-subscribe-form , .subscribe-form .info-text {
        width: 100% !important;
    }
    .subscribe-form {
        flex-direction: column;
    }
    .options .basket.desktop-btn {
        width: 20px;
        height: 20px;
        border-radius: 50px;
        padding: 15px;
        font-size: 14px;
    }
    .App > .header .navbar .mobile-controls .btn-mobile-navigation svg {
        font-size: 20px;
    }
    .btn-mobile-navigation {
        background: #e3e3e3;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
.newsltter-section {
    background: #c9af67;
    padding: 35px 0px;
}
.subscribe-form {
    display: flex;
    justify-content: center;
    align-items: center;
}
.subscribe-form .info-text {
    width: 60%;
    color: #fff;
}
#mc-embedded-subscribe-form {
    width: 40%;
    position: relative;
}
.subscribe-form input {
    border: 1px solid #fff;
    height: 55px;
    padding: 0px 20px;
    font-size: 16px;
    width: 100%;
    border-radius: 50px;
    outline: none;
}
button#mc-embedded-subscribe {
    position: absolute;
    right: 10px;
    width: unset;
    top: 0;
    padding: 5px 20px !important;
    bottom: 0;
    max-height: 40px;
    margin: auto;
    border-radius: 30px !important;
    background-color: #be9f59;
    color: #fff;
    box-shadow: none;
    border: none;
    font-weight: 600 !important;
}
.product-list-item .image .btn-favourite {
    position: absolute;
    color: #ca313e;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.options-fav-view {
    display: flex;
    column-gap: 5px;
    padding: 10px;
}
.product-list-item .btn-favourite, .product-list-item .btn-quick-view {
    background-color: #eb454e;
    color: #fff;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}
.old-price-li table {
    width: 100%;
}
.product-quantities {
    display: flex;
    align-items: center;
    border-radius: 2px;
    /*border: 1px solid #e5e5e5;*/
    border: none;    
}
.minus-step, .plus-step {
    border: transparent;
    border-radius: 4px;
    height: 25px;
    padding: 0;
    width: 30px;
    outline: 0;
    /*background: #fff;*/
    /*color: #242424;*/
    /*font-weight: 400;*/
    background: #dfdfdf;
    color: #636363;
    font-weight: 900;
    font-size: 10px;
    font-family: "Verdana";
    -webkit-transition: background-color .2s ease-out;
    -moz-transition: background-color .2s ease-out;
    -o-transition: background-color .2s ease-out;
    transition: background-color .2s ease-out;
}
button.plus-step:hover, button.minus-step:hover {
    background-color: #1aabb7;
    color: #fff;
}
.minus-step{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;    
}
.plus-step{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;    
}
.product-quantities input {
    border: transparent;
    color: #242424;
    font-weight: 400;
    height: 25px;
    padding: 0;
    width: 50px;
    text-align: center;
    outline: 0;
    /*background: #fff;*/
    /*border-radius: 4px;*/
    -moz-appearance: textfield;
    background: #f0eeee;
    border-radius: 0px;
}
/* hide spin box arrows */
.product-quantities input::-webkit-outer-spin-button,
.product-quantities input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.details-pr {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    column-gap: 10px;
    flex-direction: column;
}
.inn {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 15px;
}
.inn div{
    margin: 0 auto;
}
.quantities-section{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.info > span:first-child{
    display: inline-block;
    width: 100%;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    
    line-height: 15px;
}
.add-to-basket {
    /*height: 42px;*/
    line-height: 20px;
    border-radius: 4px;
}
.fav-btn {
    height: 42px;
    line-height: 20px !important;
    border: 1px solid #e5e5e5 !important;
    color: #AAAAAA !important;
    box-sizing: border-box !important;
    border-radius: unset !important;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background: #f7f7f7 !important;
    border-radius: unset;
    border: 1px solid #f7f7f7;
    border-color: #f7f7f7 !important
}
.basket .inn-header h4{
    color: #b18c48;
}
/*
.product-quantities.baskt-control input {
    height: 30px !important;
    border-radius: unset !important;
    background-color: #f7f7f7 !important
}
.baskt-control .minus-step, .baskt-control .plus-step {
    height: 30px !important;
    background-color: #0065bd;
    border-radius: unset !important;
    color: #fff !important;
}
*/
.nav-link {
    color: #323232 !important;
}
.bskt-controls .details-pr {
    flex-direction: row;
}
.baskt-control {
    border: none !important
}
.c-red {
    color: #eb454e;
}
.c-blue {
    color: #0065bd !important;
}
.tab-content {
    margin: 0;
    padding: 25px;
    padding-top: 50px;
    box-shadow: none;
    border: 1px solid #EBEDF0;
    border-top: 0px;
}
.product-item > .info .price {
    width: 100%;
}
.qty-select {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    column-gap: 10px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.qty-select select {
    height: 30px;
    border: 1px solid #e5e5e5;
    color: #333;
}
.qty-sect .minus-step, .qty-sect .plus-step{
    background: #e5e5e5;
    border-radius: unset;
    height: 30px;
}
.qty-sect input {
    height: 30px;
}
.products-wrapper .product-item {
    margin: 4px;
    display: flex;
    flex-direction: column;
}
.btn-custom {
    background-color: #0065bd !important;
    color: #fff !important;
    border-radius: unset !important;
    font-weight: 500 !important;
}
.btn:hover {
    background-color: #0056a1 !important;
}
/* .info:before {
    content: '';
    border-top: 1px solid #efefef;
    width: 100%;
} */
.info span:first-child {
    font-weight: 600
}
.empty-list-view span {
    padding: 20px 30px !important;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 10%);
}
.banner-int {
    background-image: url(https://cdn.pixabay.com/photo/2016/05/30/18/41/smoothie-1425494_960_720.jpg);
    height: 250px;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: block;
    background-color: hsl(0deg 0% 0% / 20%);
    background-blend-mode: overlay;
}
.banner-int > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: 600;
    font-size: 30px;
}
.signup-btn-s {
    padding: 4px 8px;
    border-radius: 50px;
    color: #0065bd;
    border: 2px solid #0065bd;
    text-decoration: none;
}

@media (max-width: 991px) {
    .breadcrumb .category-elem:not(:last-child) {
        display: none;
    }
}
@media screen and (max-width:1024px) {
    .signup-page .inner-d {
        width: 100% !important;
    }
}
.years {
    display: flex;
    column-gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}
.years span {
    color: #eb454e;
    font-size: 90px;
    font-weight: 800;
}
.years p {
    margin-bottom: 5px;
}
.sub-list img {
    width: 100%;
    height: 110px;
    object-fit: cover;
    border-radius: 4px;
}

.sub-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}