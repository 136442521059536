.btn-red{
    border-color: #d42121 !important;
    background-color: #d42121 !important;    
}

.btn-red:hover {
    background-color: #d42121;
    border-color: #d42121;
}

.btn-red:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #d42121;
}

.nav-link{
    background-color: #283046;
}

.nav-tabs{
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .nav-fill .nav-item {
      width: 100% !important;
      flex-basis: unset !important;
    }
  }