.flex-group{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #404656;
  border-radius: 5px;
  margin: 10px;  
}

.flex-group *{
  margin: 5px;
}