.category-row {
  position:relative;
}
.cat-wrapper {
    width: 100%;
    position: relative;
}
.elementor-widget-container {
    position: relative;
}
.areas-item {
    margin: 5px;
    background-size: contain;
    background-position: center;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-radius: 5px;
    padding: 10px;
}

.grid-item-link.hover-effect-flat {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.areas-item-text {
    padding: 5px;
    display: flex; /* Use flexbox */
    align-items: center; /* Vertically center the items inside the container */
    justify-content: center; /* Horizontally center the items inside the container (optional) */
    height: 100px;
}

.areas-item >* {
    color: #fff;
}

.slick-prev:before, .slick-next:before {
    color: #7a7a7a !important;
}
.areas-item-text a{
    text-decoration: none;
}