.section{
    position: relative;
    border-radius: 10px;
    background-color: #fff;
    margin: 5px 0px;
}

@media screen and (max-width: 768px) {
    .section{
        background-position: center;
    }
}

.section .section-row{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid silver;
    width: 100%;    
}

:global .product-wrapper .product-item .info{
    height: auto;
}

:global .product-wrapper .product-item .info:before{
    display: none;
}

:global .product-wrapper .product-item .initial-price{
    display: block;
}

:global .product-wrapper > div:not(.product-item .quantities-section) {
    position: relative;
}

:global .product-wrapper:hover .product-item .quantities-section {
    opacity: 1;
}

:global .product-wrapper .product-item .quantities-section{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(173, 216, 230, 0.856);
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

:global .product-wrapper .product-item .image-wrapper{
    background: #fff;
}

.section-group{
    display: none;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 10px;
    border: 1px solid #404656;
    border-radius: 5px;
    margin: 30px;
    background-color: #283046de;
    position: absolute;
    z-index: 2;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;    
}

.products{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: end;
}

.section-title{
    background-color: #000000a1;
    padding: 15px;
    color: #fff;
}

.color-pick{
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;    
}

.settings-btn{
    position: absolute;
    margin: 5px;
    border-radius: 5px;
    padding: 5px;
    background-color: #fff;
}

.close{
    text-shadow: none;
    background-color: #d42121 !important;
    box-shadow: 0 3px 8px 0 rgba(25, 10, 10, 0.49) !important;    
    color: #b4b7bd;
    padding: 0.2rem 0.62rem;
    border-radius: 0.357rem;
    background: #fff;    
    position: relative;
    float: right;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;    
}