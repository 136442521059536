.product {
    display: flex;
    /*background: #fff;*/
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    color: #000;
}

.product h6{
    color: #000;
    font-size: 12px;
    text-align: center;
}

.product img{
    min-width: 150px;
    max-height: 150px;
    background-color: #fff;
    object-fit: contain;
    margin: 10px;
    padding: 10px;
    border-radius: 4px;
}

.close{
    text-shadow: none;
    background-color: #d42121 !important;
    box-shadow: 0 3px 8px 0 rgba(25, 10, 10, 0.49) !important;    
    color: #b4b7bd;
    padding: 0.2rem 0.62rem;
    border-radius: 0.357rem;
    background: #fff;    
    position: relative;
    float: right;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1;    
}