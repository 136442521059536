.go-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #0065bd;
    color: #fff;
    font-size: 16px;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s;
    z-index: 999;
  }
  
  .go-to-top-button:hover {
    opacity: 0.8;
  }
  