.brochure-page {
    display: flex;
    max-width: 1100px;
    height: auto;
    /* min-height: calc(800px * 1.414); */
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    background-color: #fff;
    padding: 7px;
  }

.brochure-page h2{
    background-color: #000000a1;
    padding: 15px;
    color: #fff;
}
  
.flex-group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border: 1px solid #404656;
    border-radius: 5px;
    margin: 10px;
    background-color: #283046;
}

.flex-group *{
    margin: 5px;
}