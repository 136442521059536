.cart-accordion {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-item {
  border-top: 1px solid #ddd;
}

.accordion-title {
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  background: #ebebeb;
  border-bottom: 1px solid #dddddd;
}

.accordion-item:last-child .accordion-title {
  border-bottom: unset;
}

.accordion-title:hover{
  background-color: #0065bd;
  color: #fff;
}

.accordion-title::before {
  content: "+";
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
}

.accordion-title.active::before {
  content: "-";
}

.accordion-content {
  padding: 5px 20px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  /* border: 1px solid #ddd; */
  border-radius: 5px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
}

.item.disabled {
  opacity: .5;
  user-select: none;
  pointer-events: none;
}

.item .buttons {
  padding: 15px 20px;
}

.item .buttons .delete-btn {
  width: 20px;
  height: 20px;
  /* background: url('/path/to/delete-icon.svg') no-repeat center; */
  background-size: contain;
  cursor: pointer;
}

.item .image {
  width: 50px;
    height: 50px;
  margin-right: 10px;
}

.item .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.item .description {
  flex-grow: 1;
  margin-right: 10px;
  font-size: 12px;
  display: flex;
  flex-direction: column;  
}

.item .code{
  color: #888;
  font-size: 12px;
}

.item .details-pr {
  width: 150px;
  text-align: center;
  margin-right: 10px;
  font-size: 12px;
}

.item .details-pr .product-quantities {
  display: flex;
  justify-content: center;
  align-items: center;
}

.item .details-pr .product-quantities input {
  width: 50px;
  text-align: center;
}

.item .price {
  width: 100px;
  text-align: right;
  font-weight: 600;
  font-size: 12px;
}
