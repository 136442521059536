._brochure-items-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px;
    padding: 0 15px;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    flex-direction: column;
}